import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Api from "../../../config/Api";
import { Modal } from "react-bootstrap";
import SDRF from "./Components/SDRF";
import { toast } from "react-toastify";
import GifImg from "../../../assets/images/loader.gif";
import { useForm } from "react-hook-form";
import StatusBadge from "./Components/StatusBadge";
// import { FaTimes, FaCheck } from "react-icons/fa";
// import { useSelector } from "react-redux";
import { FiCheckCircle } from "react-icons/fi";
import { FaArrowLeft, FaPrint } from "react-icons/fa";
import ReactToPrint from "react-to-print";

const Application = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  let { id } = useParams();
  const navigate = useNavigate();
  let application_id = window.atob(id);

  const [applicationDetail, setApplicationDetail] = useState({
    data: "",
    img_url: "",
    nature_disaster: [],
    damages: [],
    amount: "",
    document_img_url: "",
    verifying_officer: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [applicationDocuments, setApplicationDocuments] = useState([]);
  const [documentModalBox, setDocumentModalBox] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [selectedData, setSelectedData] = useState({
    status: "",
  });

  const modalClose = () => {
    setIsModalShow(false);
    reset();
    setSelectedData({
      status: "",
    });
  };
  // const modalShow = (status) => {
  //   setIsModalShow(true);
  //   setSelectedData({
  //     status,
  //   });
  // };

  const documentModalClose = () => {
    setDocumentModalBox(false);
  };
  const openModal = (data) => (e) => {
    setDocumentModalBox(true);
    setDocumentUrl(data);
  };

  //getApplication
  const getApplicationDetail = async (id) => {
    try {
      const body = {
        id,
      };
      const res = await Api("view_applicant_details", "POST", body);
      // console.log(res.data.ApplicantDetails.Details);
      // console.log(res.data.ApplicantDetails.Details.status);
      // console.log(res.data);
      if (res.data.status === 1) {
        setApplicationDetail({
          data: res.data.ApplicantDetails.Details,
          img_url: res.data.ApplicantDetails.img_url,
          nature_disaster: res.data.ApplicantDetails.nature_disaster,
          damages: res.data.ApplicantDetails.damages,
          amount: res.data.ApplicantDetails.amount,
          document_img_url: res.data.ApplicantDetails.document_img_url,
          verifying_officer: res.data.ApplicantDetails.verifying_officer,
        });
        if (!!res?.data?.ApplicantDetails?.Details?.documents?.length) {
          setApplicationDocuments(
            res?.data?.ApplicantDetails?.Details?.documents.split(",")
          );
        }

        // console.log(res?.data?.ApplicantDetails?.Details?.documents.split(','));
        setIsLoaded(true);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getApplication

  useEffect(() => {
    getApplicationDetail(application_id);
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (d) => {
    // console.log(d);
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("id", application_id);

      if (selectedData.status === "2") {
        formData.append("reject_name", d?.name);
        formData.append("reject_remark", d?.remark);
      }

      if (selectedData.status === "3") {
        formData.append("name", d?.name);
        formData.append("remark", d?.remark);
        formData.append("status", d?.status);
        if (d?.document.length > 0) {
          formData.append("verify_document", d?.document[0]);
        }
      }

      if (selectedData.status === "4") {
        formData.append("approve_remark", d?.remark);
        if (d?.document.length > 0) {
          formData.append("approve_document", d?.document[0]);
        }
      }

      // for (const pair of formData.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`);
      // }
      const res = await Api(
        `${
          selectedData.status === "2"
            ? "application_reject"
            : selectedData.status === "3"
            ? "application_verify"
            : selectedData.status === "4"
            ? "application_approve"
            : ""
        }`,
        "POST",
        formData
      );
      // console.log(res);
      if (res.data.status === 1) {
        toast.success(
          `${
            selectedData.status === "2"
              ? "Application has been rejected!"
              : selectedData.status === "3"
              ? res.data.message
              : selectedData.status === "4"
              ? "Application has been approved!"
              : "Success"
          }`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        reset();
        modalClose();
        getApplicationDetail(application_id);
        setIsLoading(false);
      } else if (res.data.status === 2) {
        reset();
        modalClose();
        getApplicationDetail(application_id);
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  let componentRef = useRef();

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div>
          <div className={`loaderoverlay ${!isLoaded && "show"}`}>
            <div>
              <img src={GifImg} alt="Loader" />
              <p>Loading...</p>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-lg-6">
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-primary mx-3"
                    onClick={() => navigate(-1)}
                  >
                    <FaArrowLeft /> Back
                  </button>
                  <h5 className="text-dark m-0">Application Detail</h5>
                  {/* <div className="mb-3">
              <ReactToPrint
                trigger={() => (
                  <button type="button" className="btn btn-success px-5">
                    <FaPrint /> Print
                  </button>
                )}
                content={() => componentRef}
              />
            </div> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex gap-3 align-items-center justify-content-end">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                      <div className="flex-shrink-0">
                        <StatusBadge status={applicationDetail?.data?.status} />
                      </div>
                    </div>
                  </form>

                  <div className="">
                    <ReactToPrint
                      trigger={() => (
                        <button type="button" className="btn btn-success px-5">
                          <FaPrint /> Print
                        </button>
                      )}
                      content={() => componentRef}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <div ref={(el) => (componentRef = el)}>
              <SDRF data={applicationDetail} />
            </div>
          </div>

          <div className="mt-4 text-center">
            <div className="mb-3">
            <button className="btn btn-primary mx-3" onClick={() => navigate(-1)}><FaArrowLeft /> Back </button>
              <ReactToPrint
                trigger={() => (
                  <button type="button" className="btn btn-success px-5">
                    <FaPrint /> Print
                  </button>
                )}
                content={() => componentRef}
              />
            </div>

            {applicationDetail.data.status === "6" && (
              <div className="row g-4 justify-content-center">
                {applicationDocuments?.length > 0 && (
                  <div className="col-lg-12">
                    <div className="alert alert-warning m-0">
                      <h3 className="text-uppercase fs-5  mb-3">Documents</h3>
                      <div className="text-center d-flex gap-3 justify-content-center">
                        {applicationDocuments?.map((data, i) => (
                          <button
                            onClick={openModal(data)}
                            key={i}
                            className="btn btn-primary"
                          >
                            Document {i + 1}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-lg-12">
                  <div className="alert alert-success thankyou_box rounded-4 p-3 m-0">
                    <FiCheckCircle className="fs-1 text-success mb-3" />
                    <h5 className="m-0">
                      The application has been approved!
                    </h5>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={isModalShow}
        onHide={modalClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">
            {selectedData.status === "2"
              ? "Reject"
              : selectedData.status === "3"
              ? "Verify"
              : selectedData.status === "4"
              ? "Approve"
              : ""}{" "}
            SDRF
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3">
                <div className="col-12">
                  <div className="alert alert-warning m-0 p-2 fw-bold">
                    {selectedData.status === "2"
                      ? "Are you sure you want to Reject this SDRF Form?"
                      : selectedData.status === "3"
                      ? "Are you sure you want to Verify this SDRF Form?"
                      : selectedData.status === "4"
                      ? "Are you sure you want to Approve this SDRF Form?"
                      : ""}
                  </div>
                </div>
                {(selectedData.status === "2" ||
                  selectedData.status === "3") && (
                  <div className="col-12">
                    <div className="">
                      <label>
                        Name
                        <span className="text-danger fw-bold">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("name", {
                          required: true,
                        })}
                      />
                      {errors.name && (
                        <div className="error_line">Name is required</div>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <div className="">
                    <label>
                      Comment/Remark
                      <span className="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      {...register("remark", {
                        required: true,
                      })}
                      rows={5}
                    ></textarea>
                    {errors.remark && (
                      <div className="error_line">
                        Comment/Remark is required
                      </div>
                    )}
                  </div>
                </div>
                {(selectedData.status === "3" ||
                  selectedData.status === "4") && (
                  <div className="col-12">
                    <div className="">
                      <label>Upload supporting file (if any)</label>
                      <input
                        className="form-control"
                        type="file"
                        {...register("document")}
                      />
                      {errors.document && (
                        <div className="error_line">
                          Supporting file is required
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {selectedData.status === "3" && (
                  <div className="col-12">
                    <div className="">
                      <label>
                        Select Status{" "}
                        <span className="text-danger fw-bold">*</span>
                      </label>
                      <select
                        className="form-select"
                        {...register("status", {
                          required: true,
                        })}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Pending</option>
                        <option value="3">Verify</option>
                      </select>
                      {errors.status && (
                        <div className="error_line">Status is required</div>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-12">
                  <div className="d-flex gap-2">
                    <button
                      type="submit"
                      className="btn btn-primary px-5"
                      disabled={isLoading}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="xl"
        show={documentModalBox}
        onHide={documentModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="document_iframe">
            <iframe
              title="Document"
              src={`${applicationDetail?.document_img_url}${documentUrl}`}
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Application;
