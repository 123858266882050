import React, { useEffect, useState } from "react";
import Api from "../../../config/Api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import GifImg from "../../../assets/images/loader.gif";
// import InfiniteScroll from "react-infinite-scroller";
// import qs from "qs";
import { useForm } from "react-hook-form";
import { BsEyeFill } from "react-icons/bs";
import StatusBadge from "../Application/Components/StatusBadge";
import Table from "../../Components/Widget/Table";
import Loader from "../../Components/Widget/Loader";
import TooltipWidget from "../../Components/Widget/TooltipWidget";

const FinalApprovedApplicationsList = () => {
  const {
    formState: { errors },
    reset,
  } = useForm();

  // const ImgUrl = "https://cdn-icons-png.flaticon.com/512/219/219983.png";
  const [isLoading, setIsLoading] = useState(false);
  const [application, setApplication] = useState([]);
  const [isApplicationLoaded, setIsApplicationLoaded] = useState(false);
  const [filter, setFilter] = useState("");
  const admin = useSelector((state) => JSON.parse(state.authentication.admin));


  //Application Get
  const getApplication = async (status_id) => {
    try {
      setIsLoading(true);
      const body = {
        status: status_id,
      };
      const res = await Api("application_list", "POST", body, true);
      // console.log(res);
      if (res.data.status === 1) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setApplication(res.data.data);
        setIsApplicationLoaded(true);
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Application Get

  //filter
  const searchInput = (e) => {
    setFilter(e.target.value);
  };

  const filtered = !filter
    ? application
    : application.filter(
        (application) =>
          application?.application_id
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.applicant_name
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.mobile?.toLowerCase().includes(filter.toLowerCase()) ||
          application?.district?.district_name?.toLowerCase().includes(filter.toLowerCase())
      );

  useEffect(() => {
    getApplication([6]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!admin) {
      reset({
        name: admin.full_name,
      });
    }
    // eslint-disable-next-line
  }, [reset]);

  const columns = [
    {
      name: "#",
      selector: (props) => filtered.indexOf(props) + 1,
      sortable: true,
    },
    {
      name: "Application ID",
      selector: (row) => row.application_id,
      sortable: true,
      wrap: true,
    },
    {
    //   name: "Applicant Image",
    name: "Applicant Passport Photo",
      selector: (row) => (
        <img
          src={row.applicant_default_img}
          alt="Applicant"
          style={{
            height: "70px",
            width: "100px",
            objectFit: "cover",
          }}
        />
      ),
    },
    {
      name: "Applicant Info",
      selector: (row) => (
        <>
          <div className="mb-1">{row.applicant_name}</div>
          <div>{row.mobile}</div>
        </>
      ),
    },
    {
      name: "District",
      selector: (row) => row.district?.district_name,
      sortable: true,
      wrap: true,
    },
    // {
    //   name: "Status",
    //   selector: (row) => <StatusBadge status={row.status} />,
    //   sortable: true,
    //   wrap: true,
    // },
    {
        name: "Status",
        selector: (row) => {
         if(row?.payment_status === 'R'){
          return (
          <>
          <StatusBadge status={row.status} /><span style={{backgroundColor: 'red',
              color: 'white',
              padding: '4px 6px',
              borderRadius: '12px',
              fontSize: '11px',}}>Failed</span>
          </>
          );
         }
         else {
         return <StatusBadge status={row.status} />
         }
        },
        sortable: true,
        wrap: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex gap-1">
          <TooltipWidget title="View SDRF">
            <Link
              className="btn btn-warning btn-sm"
            //   target="_blank"
              to={`/application/${window.btoa(row.id)}`}
            >
              <BsEyeFill />
            </Link>
          </TooltipWidget>
        </div>
      ),
      minWidth: "180px",
    },
  ];

  const [selectedRows, setSelectedRows] = useState("");

  const onSelectedRowsChange = (d) => {
    setSelectedRows(d);
  };

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="mb-3">
          <div className="row g-2">
            <div className="col-lg-6 col-md-4">
              <h5 className="text-dark">Final Approved Applications List</h5>
            </div>

            <div className="col-lg-6 col-md-8">
              <div className="">
                <input
                  type="text"
                  placeholder="Search Application"
                  className="form-control"
                  value={filter}
                  onChange={searchInput}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
        {/* {selectedRows?.selectedCount > 0 && (
          <div className="alert alert-info d-flex align-items-center p-2">
            <div className="h5 m-0 flex-grow-1">
              {selectedRows?.selectedCount} item selected
            </div>
            <button
              type="button"
              className="btn btn-success"
              onClick={bulkApprove}
            >
              <FaCheck /> Bulk Approve
            </button>
          </div>
        )} */}

        <div>
          {isApplicationLoaded ? (
            <Table
              columns={columns}
              data={filtered}
            //   selectableRows={true}
              onSelectedRowsChange={onSelectedRowsChange}
            />
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

export default FinalApprovedApplicationsList;
