import React from "react";
import {
  BiDesktop,
  BiCog,
  BiFile,
  BiUser,
  BiCheckShield,
} from "react-icons/bi";

import { FiFilePlus, FiLayout } from "react-icons/fi";
import {
  BsListUl,
  BsCloudLightningRain,
  BsArrowRight,
  BsCheck2All,
  BsCheck2,
} from "react-icons/bs";
import { TfiClose } from "react-icons/tfi";
import {
  AiOutlinePieChart,
  AiOutlineBarChart,
  AiOutlineAreaChart,
  AiOutlineDotChart,
  AiOutlineLineChart,
} from "react-icons/ai";

const useMenus = (permission) => {
  let accessMenus = [];
  // console.log(permission);
  if (permission?.includes("Home")) {
    accessMenus.push({
      path: "/home",
      name: "Home",
      icon: <BiDesktop />,
    });
  }
  if (permission?.includes("Dashboard")) {
    accessMenus.push({
      path: "/dashboard",
      name: "Dashboard",
      icon: <FiLayout />,
    });
  }
  if (
    permission?.includes("Add SDRF") ||
    permission?.includes("Manage SDRF") ||
    permission?.includes("Rejected SDRF") ||
    permission?.includes("Nodal Officer") ||
    permission?.includes("Relief Officer") ||
    permission?.includes("HOD")
  ) {
    accessMenus.push({
      // path: "/file-manager",
      name: "SDRF Management",
      icon: <BiFile />,
      subRoutes: [],
    });
  }

  if (permission?.includes("Add SDRF")) {
    let index = accessMenus.findIndex((a) => a.name === "SDRF Management");
    accessMenus[index].subRoutes.push({
      path: "/sdrf-1",
      name: "Add SDRF",
      icon: <FiFilePlus />,
    });
  }

  if (permission?.includes("Manage SDRF")) {
    let index = accessMenus.findIndex((a) => a.name === "SDRF Management");
    accessMenus[index].subRoutes.push({
      path: "/manage-applications",
      name: "Manage SDRF",
      icon: <BsListUl />,
    });
  }
  if (permission?.includes("Rejected SDRF")) {
    let index = accessMenus.findIndex((a) => a.name === "SDRF Management");
    accessMenus[index].subRoutes.push({
      path: "/rejected-applications",
      name: "Rejected SDRF",
      icon: <TfiClose />,
    });
  }
  if (permission?.includes("Nodal Officer")) {
    let index = accessMenus.findIndex((a) => a.name === "SDRF Management");
    accessMenus[index].subRoutes.push({
      path: "/verify-applications",
      name: "Verify SDRF",
      icon: <BsCheck2 />,
    });
  }
  if (permission?.includes("Relief Officer")) {
    let index = accessMenus.findIndex((a) => a.name === "SDRF Management");
    accessMenus[index].subRoutes.push({
      path: "/relief-approve-applications",
      name: "Approve SDRF",
      icon: <BsCheck2All />,
    });
  }
  if (permission?.includes("HOD")) {
    let index = accessMenus.findIndex((a) => a.name === "SDRF Management");
    accessMenus[index].subRoutes.push({
      path: "/hod-approve-applications",
      name: "Final Approve SDRF",
      icon: <BsCheck2All />,
    });
  }

  if (permission?.includes("HOD")) {
    let index = accessMenus.findIndex((a) => a.name === "SDRF Management");
    accessMenus[index].subRoutes.push({
      path: "/final-approved-applications",
      name: "Final Approved SDRF List",
      icon: <BsCheck2All />,
    });
  }

  if (
    permission?.includes("Nature Of Disaster") ||
    permission?.includes("Item Damaged") ||
    permission?.includes("Category") ||
    permission?.includes("Sub Category")
  ) {
    accessMenus.push({
      // path: "/file-manager",
      name: "Master",
      icon: <BiCog />,
      subRoutes: [],
    });
  }

  if (permission?.includes("Nature Of Disaster")) {
    let index = accessMenus.findIndex((a) => a.name === "Master");
    accessMenus[index].subRoutes.push({
      path: "/nature-of-disaster",
      name: "Nature Of Disaster",
      icon: <BsCloudLightningRain />,
    });
  }

  if (permission?.includes("Item Damaged")) {
    let index = accessMenus.findIndex((a) => a.name === "Master");
    accessMenus[index].subRoutes.push({
      path: "/item-damaged",
      name: "Item Damaged",
      icon: <BsArrowRight />,
    });
  }

  if (permission?.includes("Category")) {
    let index = accessMenus.findIndex((a) => a.name === "Master");
    accessMenus[index].subRoutes.push({
      path: "/category",
      name: "Category",
      icon: <BsArrowRight />,
    });
  }

  if (permission?.includes("Sub Category")) {
    let index = accessMenus.findIndex((a) => a.name === "Master");
    accessMenus[index].subRoutes.push({
      path: "/sub-category",
      name: "Sub Category",
      icon: <BsArrowRight />,
    });
  }

  if (
    permission?.includes("Manage Role") ||
    permission?.includes("Manage User")
  ) {
    accessMenus.push({
      name: "Users",
      icon: <BiUser />,
      subRoutes: [],
    });
  }

  if (permission?.includes("Manage Role")) {
    let index = accessMenus.findIndex((a) => a.name === "Users");
    accessMenus[index].subRoutes.push({
      path: "/manage-role",
      name: "Manage Role",
      icon: <BiCheckShield />,
    });
  }

  if (permission?.includes("Manage User")) {
    let index = accessMenus.findIndex((a) => a.name === "Users");
    accessMenus[index].subRoutes.push({
      path: "/manage-user",
      name: "Manage Users",
      icon: <BiUser />,
    });
  }

  if (permission?.includes("SDRF Report")) {
    accessMenus.push({
      name: "SDRF Report",
      icon: <AiOutlinePieChart />,
      subRoutes: [
        {
          path: "/generate-report",
          name: "Generate Report",
          icon: <AiOutlineBarChart />,
        },
        {
          path: "/property-report",
          name: "Property Report",
          icon: <AiOutlineAreaChart />,
        },
        {
          path: "/calamity-report",
          name: "Calamity Report",
          icon: <AiOutlineDotChart />,
        },
        {
          path: "/custom-report",
          name: "Custom Report",
          icon: <AiOutlineLineChart />,
        },
        {
          path: "/historical-report",
          name: "Historical Report",
          icon: <AiOutlineBarChart />,
        },
        {
          path: "/graphical-report",
          name: "Graphical Report",
          icon: <AiOutlinePieChart />,
        },
      ],
    });
  }

  // if (permission.includes("Generate Report")) {
  //   let index = accessMenus.findIndex((a) => a.name === "SDRF Report");
  //   accessMenus[index].subRoutes.push({
  //     path: "/generate-report",
  //     name: "Generate Report",
  //     icon: <AiOutlineBarChart />,
  //   });
  // }

  // if (permission.includes("SDRF Per Property")) {
  //   let index = accessMenus.findIndex((a) => a.name === "SDRF Report");
  //   accessMenus[index].subRoutes.push({
  //     path: "/dashboard",
  //     name: "SDRF Per Property",
  //     icon: <AiOutlineAreaChart />,
  //   });
  // }

  // if (permission.includes("SDRF Per Calamity")) {
  //   let index = accessMenus.findIndex((a) => a.name === "SDRF Report");
  //   accessMenus[index].subRoutes.push({
  //     path: "/dashboard",
  //     name: "SDRF Per Calamity",
  //     icon: <AiOutlineDotChart />,
  //   });
  // }

  // if (permission.includes("Custom Report")) {
  //   let index = accessMenus.findIndex((a) => a.name === "SDRF Report");
  //   accessMenus[index].subRoutes.push({
  //     path: "/dashboard",
  //     name: "Custom Report",
  //     icon: <AiOutlineLineChart />,
  //   });
  // }

  // if (permission.includes("SDRF Historical Report")) {
  //   let index = accessMenus.findIndex((a) => a.name === "SDRF Report");
  //   accessMenus[index].subRoutes.push({
  //     path: "/dashboard",
  //     name: "SDRF Historical Report",
  //     icon: <AiOutlineBarChart />,
  //   });
  // }

  // if (
  //   permission.includes("System Log") ||
  //   permission.includes("Database Backup") ||
  //   permission.includes("Fund Management")
  // ) {
  //   accessMenus.push({
  //     name: "Configuration",
  //     icon: <BiCog />,
  //     subRoutes: [],
  //   });
  // }

  // if (permission.includes("System Log")) {
  //   let index = accessMenus.findIndex((a) => a.name === "Configuration");
  //   accessMenus[index].subRoutes.push({
  //     path: "/dashboard",
  //     name: "System Log",
  //     icon: <BsListUl />,
  //   });
  // }

  // if (permission.includes("Database Backup")) {
  //   let index = accessMenus.findIndex((a) => a.name === "Configuration");
  //   accessMenus[index].subRoutes.push({
  //     path: "/dashboard",
  //     name: "Database Backup",
  //     icon: <AiOutlineDatabase />,
  //   });
  // }

  // if (permission.includes("Fund Management")) {
  //   let index = accessMenus.findIndex((a) => a.name === "Configuration");
  //   accessMenus[index].subRoutes.push({
  //     path: "/dashboard",
  //     name: "Fund Management",
  //     icon: <BiCoinStack />,
  //   });
  // }

  return accessMenus;
};

export default useMenus;
